import FemalePhotographer from "../../../public/svg/female-photographer.svg";
import { SEO } from "@/components";
import { ROUTES } from "@/config";
import { LayoutPublic, LoginForm } from "@/containers";
import { withoutAuth } from "@/hoc";
import { useGoogleLogin, useAuthRedirect, useQueryParams } from "@/hooks";
import { useAuthContext } from "@/providers";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

const Login: React.FC = () => {
  const { query } = useRouter();
  const doAuthRedirect = useAuthRedirect();
  const { setAuthenticated } = useAuthContext();
  const { planID, backPath } = useQueryParams<{
    backPath: Nullable<string>;
    planID: Nullable<string>;
  }>();

  const isSelectAuth = backPath === `/${ROUTES.SELECT.AUTH.SLUG}`;
  const isPublishAuth = backPath === `/${ROUTES.PUBLISH.AUTH.SLUG}`;
  const isSignUp = !!planID;

  const onGoogleLoginButtonClick = useOnGoogleLoginButtonClick({
    authBackPath: backPath,
    doAuthRedirect,
    setAuthenticated,
  });

  return (
    <LayoutPublic testId="page-login">
      <div className="md:pb-16">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h1 className="text-h2 font-semibold text-center leading-tight md:text-h1">
            Sign in to Narrative
          </h1>
        </div>

        <div className="relative p-15 mt-11 bg-off-white sm:mx-auto sm:w-full sm:max-w-125 md:mt-12">
          <LoginForm
            onGoogleClick={onGoogleLoginButtonClick}
            onSuccess={async () => setAuthenticated(true)}
          />
          <p className="mt-6 text-xxs text-gray-700 text-center">
            {isSignUp ? "Don’t have a Narrative account?" : "New to Narrative?"}{" "}
            <Link
              href={{
                query,
                pathname: getSignupPathname({
                  isSelectAuth,
                  isPublishAuth,
                  isSignUp,
                }),
              }}
            >
              <a
                className="text-gray-700 underline hover:text-black transition duration-200"
                data-testid="login-form-start-trial-link"
              >
                {isSignUp ? "Create an account" : "Get started for free"}
              </a>
            </Link>
          </p>

          <div className="hidden absolute top-0 right-0 mt-6 -mr-110 md:block">
            <Image
              src={FemalePhotographer}
              alt=""
              width="358"
              height="421"
              priority
            />
          </div>
        </div>
      </div>
    </LayoutPublic>
  );
};

const Page = withoutAuth(Login);

// Allow meta tags to be statically generated
const PageWithSEO: React.FC = () => (
  <>
    <SEO title="Sign in | Narrative" />
    <Page />
  </>
);

/**
 * For redirecting users to the sign up page corresponding to the sign-in context they were in.
 *
 * @function getSignupPathname
 */
const getSignupPathname = ({
  isSelectAuth,
  isPublishAuth,
  isSignUp,
}: {
  isSelectAuth: boolean;
  isPublishAuth: boolean;
  isSignUp: boolean;
}) => {
  if (isSignUp) {
    return `/${ROUTES.SIGN_UP.SLUG}`;
  }

  if (isSelectAuth) {
    return `/${ROUTES.GET_STARTED.SELECT.SLUG}`;
  }

  if (isPublishAuth) {
    return `/${ROUTES.GET_STARTED.PUBLISH.SLUG}`;
  }

  return `/${ROUTES.GET_STARTED.BASE.SLUG}`;
};

/**
 * @hook useOnGoogleLoginButtonClick - returns handler for kicking off Google Oauth login flow.
 */
const useOnGoogleLoginButtonClick = ({
  authBackPath,
  doAuthRedirect,
  setAuthenticated,
}: {
  authBackPath: Nullable<string>;
  doAuthRedirect: ReturnType<typeof useAuthRedirect>;
  setAuthenticated: (authenticated: boolean) => void;
}) => {
  const router = useRouter();
  const onGoogleLoginSuccess = useCallback(() => {
    if (authBackPath) {
      doAuthRedirect(authBackPath, router.query);
    } else {
      setAuthenticated(true);
    }
  }, [authBackPath]);

  const { openWindow } = useGoogleLogin(onGoogleLoginSuccess);

  return openWindow;
};

export default PageWithSEO;
